import {SvgIcon} from "@progress/kendo-react-common";
import {chevronLeftIcon} from "@progress/kendo-svg-icons";
import React from "react";
import {useNavigate} from "react-router";


interface NavigationHeaderParams {
    "title": String;
    "backPath": String;
}

export default function NavigationHeader(navigation: NavigationHeaderParams) {

    const navigate = useNavigate();

    const {backPath, title} = navigation;

    function handleBack() {
        navigate(backPath.toString());
    }

    return (
        <React.Fragment>
            <div className="navigation-header">
                <div className="row-direction af-cursor-pointer" onClick={handleBack}>
                    <SvgIcon className="af-cursor-pointer" icon={chevronLeftIcon} size="large" onClick={handleBack}/>
                    <div className="fullCenter text-size-m">{title}</div>
                </div>
            </div>
        </React.Fragment>

    )
}