import React, {useEffect} from 'react';
import {loadMessages, LocalizationProvider} from '@progress/kendo-react-intl';
import {useSettingSelector} from "./redux/store";
import {messages} from "./i18n/messages";
import {useDispatch} from "react-redux";
import {setLanguage} from "./redux/settingSlice";
import Layout from "./layout/Layout";
import Routing from "./layout/Routing";
import '../src/asset/style/css/netdriven.css';

function App() {

    const dispatch = useDispatch();
    const language = useSettingSelector((state) => state.setting.language);

    function getLanguage() {
        const browserLocale = navigator.language;
        const browserLanguage = browserLocale?.split(/-|_/)[0];
        let userLanguage = localStorage.getItem("userLanguage");
        if (!userLanguage) {
            return browserLanguage;
        }
        return userLanguage;
    }

    loadMessages(messages["de"], "de");

    useEffect(() => {

        const language = getLanguage()
        if (language) {
            dispatch(setLanguage(language));
        }
    }, []);

    return (
        <LocalizationProvider language={language}>
            <Layout>
                <Routing />
            </Layout>
        </LocalizationProvider>
    );
}

export default App;
