import React from 'react';

interface DevIconProps {
    manufacturer: string;
    href: string;
    isShort?: boolean;
}

export default function DevIcon({manufacturer, href, isShort = false}: DevIconProps) {

    const basePath = "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/";
    const fileSuffix = `-original.svg`;
    // const fileSuffix = `-original-wordmark.svg`;
    const fileSuffixShort = `-original.svg`;

    return (
        // if(is)
        <a href={href} title={manufacturer} target="_blank" rel="me">
            <img src={`${basePath}/${manufacturer}/${manufacturer}${isShort ? fileSuffixShort : fileSuffix}`}
                 className="dev-icon"/>
        </a>
    );

    // <a href="https://www.java.com/" title="Java"><img src="https://github.com/get-icon/geticon/raw/master/icons/java.svg" alt="Java" width="21px" height="21px"></a>
}
