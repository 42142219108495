import React from 'react';
import {Route, Routes} from 'react-router';
import {LegalNotice} from "../content/LegalNotice";
import {Privacy} from "../content/Privacy";
import {LandingPage} from "../content/LandingPage";
import {TechStack} from "../content/TechStack";

export default function Routing() {

    function getRoutes() {
        return (
            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/techstack" element={<TechStack/>}/>
                <Route path="/legalnotice" element={<LegalNotice/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
            </Routes>
        )
    }

    return (
        <React.Fragment>
            {/*<div className="cr cr-top cr-left cr-sticky cr-red">Beta</div>*/}
            {getRoutes()}
        </React.Fragment>
    );
}