import React from 'react';
import {defaultMessages, trademarks_content, trademarks_linux_content, trademarks_title} from "../i18n/messages";
import {Message} from "../i18n/Message";

export function Trademarks() {

    return (
        <div>
            <h1><Message messageKey={trademarks_title} defaultMessage={defaultMessages[trademarks_title]}/></h1>
            <p><Message messageKey={trademarks_content} defaultMessage={defaultMessages[trademarks_content]}/></p>
            <p><Message messageKey={trademarks_linux_content}
                        defaultMessage={defaultMessages[trademarks_linux_content]}/></p>
        </div>
    );
}
