import * as React from 'react';

import {useLocalization} from '@progress/kendo-react-intl';

interface MessageProps {
    messageKey: string;
    defaultMessage: string;
}

export const Message = (props: MessageProps) => {
    const localization = useLocalization();

    // console.log('### props.messageKey -> ', props.messageKey);
    // console.log('### props.defaultMessage -> ', props.defaultMessage);
    return (
        // <span style={{display: 'block'}}>
        <span>
            {localization.toLanguageString(props.messageKey, props.defaultMessage)}
    </span>
    )
}
