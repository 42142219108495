import React, {useEffect} from 'react';
import {
    about_content_1,
    about_content_2,
    about_content_3,
    about_content_4,
    about_content_5,
    about_content_6,
    about_content_7,
    about_title,
    certified,
    consulting_development_title,
    defaultMessages,
    development_architecture_title,
    development_title,
    focus_title,
    industry_authorities,
    industry_banking,
    industry_experience_title,
    industry_financial,
    industry_healthcare,
    industry_it,
    industry_logistic,
    industry_retail,
    partner_status_certification,
    with_title
} from "../i18n/messages";
import {Message} from "../i18n/Message";
import {Contact} from "./Contact";
import redHatLogoReverse from "../asset/resource/Logo-Red_Hat-Ready_Bus_Partner-A-Reverse-RGB.png";
import {Card, CardBody, CardSubtitle, CardTitle} from "@progress/kendo-react-layout";
import useWindowDimensions from "../utils/useWindowDimensions";

export function LandingPage() {

    const {isResponsive} = useWindowDimensions();

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    return (
        <div style={isResponsive ? {paddingTop: "20px"} : {paddingTop: "30px"}}>
            <div className="padding-bottom-l">
                <Contact/>
            </div>
            <div className="padding-bottom-l">
                <h1><Message messageKey={about_title} defaultMessage={defaultMessages[about_title]}/></h1>
                <p><Message messageKey={about_content_1} defaultMessage={defaultMessages[about_content_1]}/></p>
                <p><Message messageKey={about_content_2} defaultMessage={defaultMessages[about_content_2]}/></p>
                <p><Message messageKey={about_content_3} defaultMessage={defaultMessages[about_content_3]}/></p>
                <p><Message messageKey={about_content_4} defaultMessage={defaultMessages[about_content_4]}/></p>
                <p><Message messageKey={about_content_5} defaultMessage={defaultMessages[about_content_5]}/></p>
                <p><Message messageKey={about_content_6} defaultMessage={defaultMessages[about_content_6]}/></p>
                <p><Message messageKey={about_content_7} defaultMessage={defaultMessages[about_content_7]}/></p>
            </div>
            <div className="padding-bottom-l">
                <h1><Message messageKey={focus_title} defaultMessage={defaultMessages[focus_title]}/></h1>
                <div className={isResponsive ? "k-card-list" : "k-card-deck"}>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Reactive Cloud-Native {<Message messageKey={development_title}
                                                                       defaultMessage={defaultMessages[development_title]}/>}</CardTitle>
                            <CardSubtitle>{<Message messageKey={with_title}
                                                    defaultMessage={defaultMessages[with_title]}/>} Quarkus, Spring,
                                Spring Boot</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Backend Software {<Message messageKey={development_architecture_title}
                                                                  defaultMessage={defaultMessages[development_architecture_title]}/>}</CardTitle>
                            <CardSubtitle>{<Message messageKey={with_title}
                                                    defaultMessage={defaultMessages[with_title]}/>} Java, Kotlin, Java Enterprise /
                                Jakarta EE</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Frontend Software {<Message messageKey={development_title}
                                                                   defaultMessage={defaultMessages[development_title]}/>}</CardTitle>
                            <CardSubtitle>{<Message messageKey={with_title}
                                                    defaultMessage={defaultMessages[with_title]}/>} React, React Native,
                                Javascript, Typescript</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Konfigurations-, Build- und Deployment Management</CardTitle>
                            <CardSubtitle>{<Message messageKey={with_title}
                                                    defaultMessage={defaultMessages[with_title]}/>} Tekton, ArgoCD,
                                GitLab, Maven, Gradle</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={consulting_development_title}
                                                 defaultMessage={defaultMessages[consulting_development_title]}/>}</CardTitle>
                            <CardSubtitle>{<Message messageKey={with_title}
                                                    defaultMessage={defaultMessages[with_title]}/>} Red Hat® OpenShift®,
                                Kubernetes, Red Hat® JBoss® Enterprise Application Platform</CardSubtitle>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="padding-bottom-l">
                <h1>{<Message messageKey={partner_status_certification}
                              defaultMessage={defaultMessages[partner_status_certification]}/>}</h1>
                <div>
                    <img src={redHatLogoReverse} className="redhat-logo"/>
                </div>
                <h2>{<Message messageKey={certified} defaultMessage={defaultMessages[certified]}/>}</h2>
                <div className={isResponsive ? "k-card-list" : "k-card-deck"}>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Red Hat® Delivery Specialist</CardTitle>
                            <CardSubtitle>Cloud-Native Development with Quarkus I + II</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Red Hat® Sales Specialist </CardTitle>
                            <CardSubtitle>Red Hat® OpenShift®</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Red Hat® Sales Specialist </CardTitle>
                            <CardSubtitle>Cloud-Native Development</CardSubtitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>Red Hat® Sales Specialist </CardTitle>
                            <CardSubtitle>Middleware Application Development</CardSubtitle>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="padding-bottom-l">
                <h1>{<Message messageKey={industry_experience_title}
                              defaultMessage={defaultMessages[industry_experience_title]}/>}</h1>
                <div className={isResponsive ? "k-card-list" : "k-card-deck"}>
                    {/*<div>*/}
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_retail}
                                                 defaultMessage={defaultMessages[industry_retail]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_it}
                                                 defaultMessage={defaultMessages[industry_it]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_authorities}
                                                 defaultMessage={defaultMessages[industry_authorities]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_financial}
                                                 defaultMessage={defaultMessages[industry_financial]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_healthcare}
                                                 defaultMessage={defaultMessages[industry_healthcare]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_logistic}
                                                 defaultMessage={defaultMessages[industry_logistic]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                    <Card className="card-box-default">
                        <CardBody>
                            <CardTitle>{<Message messageKey={industry_banking}
                                                 defaultMessage={defaultMessages[industry_banking]}/>}</CardTitle>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

    );
}
