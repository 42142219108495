import React from 'react';

interface DevIconProps {
    manufacturer: string;
    href: string;
    fileName: string;
    isShort?: boolean;
    revert?: boolean;
}

export default function TechIcon({manufacturer, href, fileName, isShort = false, revert = false}: DevIconProps) {

    const basePath = "https://github.com/get-icon/geticon/raw/master/icons/";

    return (
        <span className="padding-left-right-l">
            <a href={href} title={manufacturer} target="_blank" rel="me">
                <img src={`${basePath}/${fileName}`} alt={manufacturer} width="50px" height="50px" style={revert ? {backgroundColor: "white"} : {}}/>
            </a>
        </span>
    )

}
