import React from 'react';

interface MailToProps {
    email: string;
    subject?: string | any;
    body?: string;
    children: any;
}

export default function MailTo({email, subject = '', body = '', children}: MailToProps) {

    // https://www.30secondsofcode.org/react/s/mailto/
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return (
        <a href={`mailto:${email}${params}`}>{children}</a>
    );
}
