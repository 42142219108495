export const link_contact = 'link_contact';
export const link_impressum = 'link_impressum';
export const link_privacy = 'link_privacy';
export const about_title = 'about_title';
export const focus_title = 'focus_title';
export const development_title = 'development_title';
export const development_architecture_title = 'development_architecture_title';
export const consulting_development_title = 'consulting_development_title';
export const with_title = 'with_title';
export const within = 'within';
export const about_content_1 = 'about_content_1';
export const about_content_2 = 'about_content_2';
export const about_content_3 = 'about_content_3';
export const about_content_4 = 'about_content_4';
export const about_content_5 = 'about_content_5';
export const about_content_6 = 'about_content_6';
export const about_content_7 = 'about_content_7';
export const industry_retail = 'industry_retail';
export const industry_it = 'industry_it';
export const industry_authorities = 'industry_authorities';
export const industry_financial = 'industry_financial';
export const industry_healthcare = 'industry_healthcare';
export const industry_logistic = 'industry_logistic';
export const industry_banking = 'industry_banking';
export const industry_experience_title = 'industry_experience_title';
export const partner_status_certification = 'partner_status_certification';
export const certified = 'certified';
export const techStack = 'techStack';
export const inquiry = 'inquiry';
export const skills_title = 'skills_title';
export const programming_language_title = 'programming_language_title';
export const microservices_framework_title = 'microservices_framework_title';
export const cloud_environment_title = 'cloud_environment_title';
export const application_server_title = 'application_server_title';
export const tools_title = 'tools_title';
export const database_title = 'database_title';
export const operating_systems_title = 'operating_systems_title';
export const quality_assurance_title = 'quality_assurance_title';
export const build_cicd_title = 'build_cicd_title';
export const task_documentation_title = 'task_documentation_title';
export const logging_monitoring_title = 'logging_monitoring_title';
export const editorial_responsibility_title = 'editorial_responsibility_title';
export const disclaimer_title = 'disclaimer_title';
export const disclaimer_0_title = 'disclaimer_0_title';
export const disclaimer_1_title = 'disclaimer_1_title';
export const disclaimer_2_title = 'disclaimer_2_title';
export const disclaimer_3_title = 'disclaimer_3_title';
export const disclaimer_4_title = 'disclaimer_4_title';
export const disclaimer_0_content = 'disclaimer_0_content';
export const disclaimer_1_content = 'disclaimer_1_content';
export const disclaimer_2_content = 'disclaimer_2_content';
export const disclaimer_3_content = 'disclaimer_3_content';
export const disclaimer_4_content = 'disclaimer_4_content';
export const trademarks_title = 'trademarks_title';
export const trademarks_content = 'trademarks_content';
export const trademarks_linux_content = 'trademarks_linux_content';

export const messages = {
    'de': {
        [link_contact]: 'Kontakt',
        [link_impressum]: 'Impressum',
        [link_privacy]: 'Datenschutzerklärung',
        [about_title]: 'Über mich',
        [focus_title]: 'Fähigkeiten und Know-how',
        [development_title]: 'Entwicklung',
        [development_architecture_title]: 'Entwicklung und Architektur',
        [consulting_development_title]: 'Beratung und Entwicklung',
        [with_title]: 'mit',
        [within]: 'im Bereich',
        [about_content_1]: 'Ich bin ein begeisterter und unabhängiger Fullstack Softwareentwickler, Berater, Freiberufler, aktiv seit JDK-1.1 und J2EE.',
        [about_content_2]: 'Als Freelancer bin ich auf die Entwicklung von individueller Softwareanwendungen und Softwareprodukten spezialisiert, die auf Java, Kotlin, JEE und React basieren.',
        [about_content_3]: 'Dazu gehört die Planung, das Design, die Implementierung und die Wartung von Softwareanwendungen.',
        [about_content_4]: 'Ich verfüge über fundierte Kenntnisse in der Java / JEE Programmierung, Cloud Native Entwicklung, Build- und Rollout Prozesse, Datenbanken, Web-Entwicklung und anderen relevanten Technologien.',
        [about_content_5]: 'Einen hohen Stellenwert hat für mich die gute Zusammenarbeit in einem Team mit anderen Entwicklern, Ingenieuren, Architekten, Produktverantwortliche und Stakeholder, um sicherzustellen, dass die Softwareprodukte den Anforderungen der Kunden entsprechen und reibungslos funktionieren.',
        [about_content_6]: 'Als Red Hat® Business Partner arbeite ich auch eng mit Red Hat® zusammen, um Kunden bei der Implementierung von Red Hat®-Technologien und -Lösungen zu unterstützen.',
        [about_content_7]: 'Dabei biete ich Beratung, Schulung, Support und andere Dienstleistungen an, um sicherzustellen, dass Kunden die bestmögliche Erfahrung mit Red Hat®-Produkten haben, die neuesten Technologien und Lösungen nutzen können, um ihre Geschäftsziele zu erreichen.',
        [industry_retail]: 'Einzelhandel und Onlineshop',
        [industry_it]: 'IT und Telekommunikation',
        [industry_authorities]: 'Behörden',
        [industry_financial]: 'Finanzverwaltung und öffentliche Einrichtungen',
        [industry_healthcare]: 'Gesundheitswesen und Telemedizin',
        [industry_logistic]: 'Transport und Logistik',
        [industry_banking]: 'Banken und Finanzdienstleistungen',
        [industry_experience_title]: 'Branchenerfahrungen',
        [partner_status_certification]: 'Partner Status und Zertifikate',
        [certified]: 'Zertifiziert',
        [techStack]: 'Tech Stack',
        [inquiry]: 'Anfrage',
        [skills_title]: 'Technologie Stack',
        [programming_language_title]: 'Programmiersprachen',
        [microservices_framework_title]: 'Microservices und Frameworks',
        [cloud_environment_title]: 'Cloud Umgebungen',
        [application_server_title]: 'Applikationsserver',
        [tools_title]: 'Tools',
        [database_title]: 'Datenbanken',
        [operating_systems_title]: 'Betriebssysteme',
        [quality_assurance_title]: 'Qualitätssicherung',
        [build_cicd_title]: 'Build, CI und CD',
        [task_documentation_title]: 'Aufgabenverwaltung und Dokumentation',
        [logging_monitoring_title]: 'Logging und Monitoring',
        [editorial_responsibility_title]: 'Redaktionell verantwortlich',
        [disclaimer_title]: 'Haftungsausschluss',
        [disclaimer_0_title]: 'Inhalt',
        [disclaimer_1_title]: 'Verweise und Links',
        [disclaimer_2_title]: 'Urheber- und Kennzeichenrecht',
        [disclaimer_3_title]: 'Datenschutz',
        [disclaimer_4_title]: 'Rechtswirksamkeit dieses Haftungsausschlusses',
        [disclaimer_0_content]: 'Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.\n' +
        'Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.',
        [disclaimer_1_content]: `Bei direkten oder indirekten Verweisen auf fremde Webseiten ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. 
        Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft 
        der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. 
        Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, 
        Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder 
        Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.`,
        [disclaimer_2_content]: `Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, 
        von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes 
        genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen 
        Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte 
        bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.`,
        [disclaimer_3_content]: `Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers 
        auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. 
        Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist 
        nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten. `,
        [disclaimer_4_content]: `Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, 
        nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.`,
        [trademarks_title]: 'Warenzeichen',
        [trademarks_content]: 'Red Hat®, OpenShift®, JBoss®, Ansible® sind Marken von Red Hat, Inc., eingetragen in den Vereinigten Staaten und anderen Ländern.',
        [trademarks_linux_content]: 'Linux ist die eingetragene Marke von Linus Torvalds in den Vereinigten Staaten und anderen Ländern.'
    }
};

export const defaultMessages = {
    [link_contact]: 'Contact',
    [link_impressum]: 'Legal Notice',
    [link_privacy]: 'Privacy',
    [about_title]: 'About me',
    [focus_title]: 'Skills and Knowledge',
    [development_title]: 'Development',
    [development_architecture_title]: 'Development and Architecture',
    [consulting_development_title]: 'Consulting and Development',
    [with_title]: 'with',
    [within]: 'within',
    [about_content_1]: 'I\'m an enthusiastic and independent full stack software developer, consultant, freelancer, active since JDK-1.1 and J2EE.',
    [about_content_2]: 'As a freelancer, I specialize in developing custom software applications and software products based on Java, Kotlin, JEE and React.',
    [about_content_3]: 'This includes the planning, design, implementation and maintenance of software applications.',
    [about_content_4]: 'I have in-depth knowledge of Java / JEE programming, cloud native development, build and rollout processes, databases, web development and other relevant technologies.',
    [about_content_5]: 'Good teamwork with other developers, engineers, architects, product managers and stakeholders is very important to me to ensure that the software products meet customer requirements and function smoothly.',
    [about_content_6]: 'As a Red Hat® Business Partner, I also work closely with Red Hat® to help customers implement Red Hat® technologies and solutions.',
    [about_content_7]: 'I provide consulting, training, support, and other services to ensure customers have the best possible experience with Red Hat® products, and are able to leverage the latest technologies and solutions to achieve their business goals.',
    [industry_retail]: 'Retail and Onlineshop',
    [industry_it]: 'IT and Telekommunication',
    [industry_authorities]: 'Authorities',
    [industry_financial]: 'Financial Management and Public Institutions',
    [industry_healthcare]: 'Healthcare and Telemedicine',
    [industry_logistic]: 'Transportation and Logistics',
    [industry_banking]: 'Banks and Financial Services',
    [industry_experience_title]: 'Industry Experiences',
    [partner_status_certification]: 'Partner Status and Certifications',
    [certified]: 'Certified',
    [techStack]: 'Tech Stack',
    [inquiry]: 'Inquiry',
    [skills_title]: 'Technology Stack',
    [programming_language_title]: 'Programming language',
    [microservices_framework_title]: 'Microservices and Frameworks',
    [cloud_environment_title]: 'Cloud Environment',
    [application_server_title]: 'Applikation Server',
    [tools_title]: 'Tools',
    [database_title]: 'Databases',
    [operating_systems_title]: 'Operating Systems',
    [quality_assurance_title]: 'Quality Assurance',
    [build_cicd_title]: 'Build, CI and CD',
    [task_documentation_title]: 'Task Management and Documentation',
    [logging_monitoring_title]: 'Logging and Monitoring',
    [editorial_responsibility_title]: 'Editorial responsibility',
    [disclaimer_title]: 'Disclaimer',
    [disclaimer_0_title]: 'Content',
    [disclaimer_1_title]: 'Referrals and links',
    [disclaimer_2_title]: 'Copyright',
    [disclaimer_3_title]: 'Privacy policy',
    [disclaimer_4_title]: 'Legal validity of this disclaimer',
    [disclaimer_0_content]: `The author reserves the right not to be responsible for the topicality, correctness, completeness or quality of the information provided. Liability claims regarding damage caused by the use of any information provided, 
    including any kind of information which is incomplete or incorrect,will therefore be rejected. All offers are not-binding and without obligation. Parts of the pages or the complete publication including all offers and information might be extended, 
    changed or partly or completely deleted by the author without separate announcement.`,
    [disclaimer_1_content]: `The author is not responsible for any contents linked or referred to from his pages - unless he has full knowledge of illegal contents and would be able to prevent the visitors of his site fromviewing those pages. 
    If any damage occurs by the use of information presented there, only the author of the respective pages might be liable, not the one who has linked to these pages. Furthermore the author is not liable for any postings or messages published 
    by users of discussion boards, guestbooks or mailinglists provided on his page.`,
    [disclaimer_2_content]: `The author intended not to use any copyrighted material for the publication or, if not possible, to indicate the copyright of the respective object. 
    The copyright for any material created by the author is reserved. Any duplication or use of objects such as images, diagrams, sounds or texts in other electronic or printed publications is not permitted without the author's agreement.`,
    [disclaimer_3_content]: `If the opportunity for the input of personal or business data (email addresses, name, addresses) is given, the input of these data takes place voluntarily. The use and payment of all offered services are permitted - 
    if and so far technically possible and reasonable - without specification of any personal data or under specification of anonymized data or an alias. The use of published postal addresses, telephone or fax numbers and email addresses for marketing 
    purposes is prohibited, offenders sending unwanted spam messages will be punished.`,
    [disclaimer_4_content]: `This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.`,
    [trademarks_title]: 'Trademarks',
    [trademarks_content]: 'Red Hat®, OpenShift®, JBoss®, Ansible® are trademarks of Red Hat, Inc., registered in the UnitedStates and other countries.',
    [trademarks_linux_content]: 'Linux is the registered trademark of Linus Torvalds in the United States and other countries.'

};

