import React from 'react';
import {
    defaultMessages,
    disclaimer_0_content,
    disclaimer_0_title,
    disclaimer_1_content,
    disclaimer_1_title,
    disclaimer_2_content,
    disclaimer_2_title,
    disclaimer_3_content,
    disclaimer_3_title,
    disclaimer_4_content,
    disclaimer_4_title,
    disclaimer_title
} from "../i18n/messages";
import {Message} from "../i18n/Message";

export function Disclaimer() {

    // https://www.disclaimer.de/disclaimer.htm?farbe=E5E5FF/000088/1111FF/1111FF
    return (
        <div>
            <h1><Message messageKey={disclaimer_title} defaultMessage={defaultMessages[disclaimer_title]}/></h1>
            <h2><Message messageKey={disclaimer_0_title} defaultMessage={defaultMessages[disclaimer_0_title]}/></h2>
            <p><Message messageKey={disclaimer_0_content} defaultMessage={defaultMessages[disclaimer_0_content]}/></p>
            <h2><Message messageKey={disclaimer_1_title} defaultMessage={defaultMessages[disclaimer_1_title]}/></h2>
            <p><Message messageKey={disclaimer_1_content} defaultMessage={defaultMessages[disclaimer_1_content]}/></p>
            <h2><Message messageKey={disclaimer_2_title} defaultMessage={defaultMessages[disclaimer_2_title]}/></h2>
            <p><Message messageKey={disclaimer_2_content} defaultMessage={defaultMessages[disclaimer_2_content]}/></p>
            <h2><Message messageKey={disclaimer_3_title} defaultMessage={defaultMessages[disclaimer_3_title]}/></h2>
            <p><Message messageKey={disclaimer_3_content} defaultMessage={defaultMessages[disclaimer_3_content]}/></p>
            <h2><Message messageKey={disclaimer_4_title} defaultMessage={defaultMessages[disclaimer_4_title]}/></h2>
            <p><Message messageKey={disclaimer_4_content} defaultMessage={defaultMessages[disclaimer_4_content]}/></p>
        </div>

    );
}
