import React, {useEffect} from 'react';
import DevIcon from "../component/DevIcon";
import {Message} from "../i18n/Message";
import {
    application_server_title,
    build_cicd_title,
    cloud_environment_title, database_title,
    defaultMessages, logging_monitoring_title,
    microservices_framework_title, operating_systems_title,
    programming_language_title, quality_assurance_title,
    skills_title, task_documentation_title, tools_title
} from "../i18n/messages";
import TechIcon from "../component/TechIcon";
import NavigationHeader from "../layout/NavigationHeader";

export function TechStack() {

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    return (
        <div>
            <NavigationHeader title="Home" backPath="/"/>
            <h1>{<Message messageKey={skills_title} defaultMessage={defaultMessages[skills_title]}/>}</h1>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={programming_language_title}
                              defaultMessage={defaultMessages[programming_language_title]}/>}</h2>
                <TechIcon manufacturer="Java" href="https://www.java.com/" fileName="java.svg"/>
                <TechIcon manufacturer="Kotlin" href="https://kotlinlang.org/" fileName="kotlin.svg"/>
                <TechIcon manufacturer="Typescript" href="https://www.typescriptlang.org/" fileName="typescript-icon.svg"/>
                <TechIcon manufacturer="JavaScript" href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" fileName="javascript.svg"/>
                <TechIcon manufacturer="ECMAScript 6" href="https://tc39.es/ecma262/" fileName="es6.svg"/>
                <TechIcon manufacturer="PHP" href="https://php.net/" fileName="php.svg"/>
                <TechIcon manufacturer="C++" href="https://isocpp.org/" fileName="c-plusplus.svg"/>
                <TechIcon manufacturer="C#" href="https://csharp.net/" fileName="c-sharp.svg"/>

            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={microservices_framework_title}
                              defaultMessage={defaultMessages[microservices_framework_title]}/>}</h2>
                <TechIcon manufacturer="Quarkus" href="https://quarkus.io/" fileName="quarkus-icon.svg"/>
                <TechIcon manufacturer="Spring" href="https://spring.io/" fileName="spring.svg"/>
                <TechIcon manufacturer="React" href="https://reactjs.org/" fileName="react.svg"/>
                <TechIcon manufacturer="React Native" href="https://reactnative.dev/" fileName="react.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={cloud_environment_title}
                              defaultMessage={defaultMessages[cloud_environment_title]}/>}</h2>
                <TechIcon manufacturer="OpenShift" href="https://www.openshift.com/" fileName="openshift.svg"/>
                <TechIcon manufacturer="Kubernetes" href="https://kubernetes.io/" fileName="kubernetes.svg"/>
                <TechIcon manufacturer="Google Cloud Platform" href="https://cloud.google.com/gcp/" fileName="google-cloud-platform.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={application_server_title}
                              defaultMessage={defaultMessages[application_server_title]}/>}</h2>
                <TechIcon manufacturer="RedHat" href="https://www.redhat.com/" fileName="redhat.svg"/>
                <TechIcon manufacturer="Wildfly" href="https://www.wildfly.org" fileName="wildfly.svg" revert={true}/>
                <TechIcon manufacturer="Apache" href="https://www.apache.org/" fileName="apache.svg"/>
                <TechIcon manufacturer="Apache Tomcat" href="https://tomcat.apache.org/" fileName="tomcat.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={tools_title} defaultMessage={defaultMessages[tools_title]}/>}</h2>
                <TechIcon manufacturer="Rest" href="https://www.w3.org/2001/sw/wiki/REST" fileName="rest.svg"/>
                <TechIcon manufacturer="GraphQL" href="https://graphql.org/" fileName="graphql.svg"/>
                <TechIcon manufacturer="Kafka" href="https://kafka.apache.org/" fileName="kafka-icon.svg" revert={true}/>
                <TechIcon manufacturer="Bash" href="https://www.gnu.org/software/bash/" fileName="bash.svg"/>
                <TechIcon manufacturer="docker" href="https://www.docker.com/" fileName="docker-icon.svg"/>
                <DevIcon manufacturer="podman" href="https://podman.io/"/>
                <TechIcon manufacturer="Redux" href="https://redux.js.org/" fileName="redux.svg"/>
                <TechIcon manufacturer="npm" href="https://www.npmjs.com/" fileName="npm.svg"/>
                <TechIcon manufacturer="Yarn" href="https://yarnpkg.com/" fileName="yarn.svg"/>
                <TechIcon manufacturer="CSS3" href="https://www.w3.org/TR/CSS/" fileName="css-3.svg"/>
                <TechIcon manufacturer="Sass" href="https://sass-lang.com/" fileName="sass.svg"/>
                <TechIcon manufacturer="Node.js" href="https://nodejs.org/" fileName="nodejs-icon.svg"/>
                <TechIcon manufacturer="webpack" href="https://webpack.js.org/" fileName="webpack.svg"/>
                <TechIcon manufacturer="Babel" href="https://babeljs.io/" fileName="babel.svg"/>
                <TechIcon manufacturer="ESLint" href="https://eslint.org/" fileName="eslint.svg"/>
                <TechIcon manufacturer="Prettier" href="https://prettier.io/" fileName="prettier.svg"/>
                <TechIcon manufacturer="Jest" href="https://jestjs.io/" fileName="jest.svg"/>
                <TechIcon manufacturer="Sublime Text" href="https://www.sublimetext.com/" fileName="sublime-text.svg"/>
                <TechIcon manufacturer="Python" href="https://www.python.org/" fileName="python.svg"/>
                <TechIcon manufacturer="Git" href="https://git-scm.com/" fileName="git-icon.svg"/>
                <TechIcon manufacturer="Bitbucket" href="https://bitbucket.org/" fileName="bitbucket.svg"/>
                <TechIcon manufacturer="Subversion" href="https://subversion.apache.org/" fileName="subversion.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={database_title} defaultMessage={defaultMessages[database_title]}/>}</h2>
                <TechIcon manufacturer="MongoDB" href="https://www.mongodb.org/" fileName="mongodb-icon.svg"/>
                <TechIcon manufacturer="PostgreSQL" href="https://www.postgresql.org/" fileName="postgresql.svg"/>
                <TechIcon manufacturer="MySQL" href="https://dev.mysql.com/" fileName="mysql.svg"/>
                <TechIcon manufacturer="Firebase" href="https://www.firebase.com/" fileName="firebase.svg"/>
                <TechIcon manufacturer="Redis" href="https://redis.io/" fileName="redis.svg"/>
                <TechIcon manufacturer="Oracle" href="https://www.oracle.com/database/" fileName="oracle.svg"/>

            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={operating_systems_title}
                              defaultMessage={defaultMessages[operating_systems_title]}/>}</h2>
                <TechIcon manufacturer="RedHat" href="https://www.redhat.com/" fileName="redhat.svg"/>
                <TechIcon manufacturer="CentOS" href="https://www.centos.org/" fileName="centos-icon.svg"/>
                <TechIcon manufacturer="debian" href="https://www.debian.org/" fileName="debian.svg"/>
                <TechIcon manufacturer="Linux" href="https://www.linuxfoundation.org/" fileName="linux-tux.svg"/>
                <TechIcon manufacturer="Mac OS" href="https://www.apple.com/macos/" fileName="macOS.svg" revert={true}/>
                <TechIcon manufacturer="Windows" href="https://www.microsoft.com/windows" fileName="microsoft-windows.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={quality_assurance_title}
                              defaultMessage={defaultMessages[quality_assurance_title]}/>}</h2>
                <TechIcon manufacturer="SonarQube" href="https://www.sonarqube.org/" fileName="sonarqube.svg" revert={true}/>
                <TechIcon manufacturer="Snyk" href="https://snyk.io/" fileName="snyk.svg"/>
                <TechIcon manufacturer="JUnit" href="https://junit.org/junit5/" fileName="junit.svg"/>
                <TechIcon manufacturer="Mockito" href="https://site.mockito.org/" fileName="mockito.svg"/>
                <TechIcon manufacturer="Jest" href="https://jestjs.io/" fileName="jest.svg"/>
                <TechIcon manufacturer="Cypress" href="https://www.cypress.io/" fileName="cypress.svg"/>
                <TechIcon manufacturer="Puppeteer" href="https://developers.google.com/web/tools/puppeteer/" fileName="puppeteer.svg"/>

            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={build_cicd_title} defaultMessage={defaultMessages[build_cicd_title]}/>}</h2>
                <TechIcon manufacturer="Maven" href="https://maven.apache.org/" fileName="maven.svg" revert={true}/>
                <TechIcon manufacturer="Gradle" href="https://gradle.org/" fileName="gradle.svg"/>
                <DevIcon manufacturer="argocd" href="https://argo-cd.readthedocs.io"/>
                <TechIcon manufacturer="Tekton" href="https://tekton.dev/" fileName="tekton.svg"/>
                <TechIcon manufacturer="Gitlab" href="https://about.gitlab.com/" fileName="gitlab.svg"/>
                <TechIcon manufacturer="Ansible" href="https://www.ansible.com/" fileName="ansible.svg"/>
            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={task_documentation_title}
                              defaultMessage={defaultMessages[task_documentation_title]}/>}</h2>
                <TechIcon manufacturer="JIRA" href="https://www.atlassian.com/software/jira" fileName="jira.svg"/>
                <TechIcon manufacturer="Confluence" href="https://www.atlassian.com/software/confluence" fileName="confluence.svg"/>
                <TechIcon manufacturer="MediaWiki" href="https://www.mediawiki.org/wiki/MediaWiki" fileName="mediawiki.svg"/>

            </div>
            <div className="padding-bottom-l">
                <h2>{<Message messageKey={logging_monitoring_title}
                              defaultMessage={defaultMessages[logging_monitoring_title]}/>}</h2>
                <TechIcon manufacturer="Datadog" href="https://www.datadoghq.com/" fileName="datadog.svg"/>
                <TechIcon manufacturer="Elasticsearch" href="https://www.elastic.co/products/elasticsearch" fileName="elasticsearch.svg"/>
                <TechIcon manufacturer="Prometheus" href="https://prometheus.io/" fileName="prometheus.svg"/>
                <TechIcon manufacturer="Grafana" href="https://grafana.com/" fileName="grafana.svg"/>
            </div>
        </div>

    );
}
