import * as React from 'react';

import {AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import netdrivenLogo from '../asset/resource/netdriven_without_slogan.png';
import {Button} from "@progress/kendo-react-buttons";
import {useDispatch} from "react-redux";
import {setLanguage} from "../redux/settingSlice";
import {useNavigate} from "react-router";
import {defaultMessages, techStack} from "../i18n/messages";
import {Message} from "../i18n/Message";

const TopAppBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function switchLang(event: string) {
        if (event) {
            dispatch(setLanguage(event));
        }
    }

    function handleHomeClicked() {
        navigate('/');
    }

    function handleSkillClicked() {
        navigate('/techstack');
    }

    return (
        <React.Fragment>
            <AppBar className="app-bar-top">
                <AppBarSection>
                    <img src={netdrivenLogo} alt="netdriven logo" className="App-logo" onClick={handleHomeClicked}/>
                </AppBarSection>
                <AppBarSection>
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={() => handleHomeClicked()} style={{marginLeft: "10px"}}>Home</Button>
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={() => handleSkillClicked()}>{<Message messageKey={techStack}
                                                                           defaultMessage={defaultMessages[techStack]}/>}</Button>
                </AppBarSection>
                <AppBarSpacer/>
                <AppBarSection className="actions">
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={() => switchLang('de')}>de</Button>
                    <div>|</div>
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={() => switchLang('en')}>en
                    </Button>
                </AppBarSection>
            </AppBar>

        </React.Fragment>
    );
};

export default TopAppBar;