import * as React from 'react';

import {AppBar, AppBarSection} from '@progress/kendo-react-layout';
import {defaultMessages, link_impressum, link_privacy} from "../i18n/messages";
import {Message} from "../i18n/Message";
import {Button} from "@progress/kendo-react-buttons";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {getFormattedYear} from "../utils/dateTimeUtils";
import useWindowDimensions from "../utils/useWindowDimensions";

const Footer = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isResponsive} = useWindowDimensions();


    function handleLegalNoticeClicked() {
        navigate('/legalnotice');
    }

    function handlePrivacyClicked() {
        navigate('/privacy');
    }

    return (
        <React.Fragment>
            <AppBar position='bottom' style={{backgroundColor: "var(--af-color-footer-grey)"}}>
                <AppBarSection>
                    <div className="text-size-l">(c) Enis Brulic - netdriven {getFormattedYear()}</div>
                </AppBarSection>
                {isResponsive ? <div style={{color: "var(--af-color-footer-grey)"}}>|</div> : <div style={{color: "white"}}>|</div>}
                <AppBarSection className="actions">
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={handleLegalNoticeClicked}><Message messageKey={link_impressum}
                                                                        defaultMessage={defaultMessages[link_impressum]}/>
                    </Button>
                    <div>|</div>
                    <Button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={handlePrivacyClicked}><Message messageKey={link_privacy}
                                                                    defaultMessage={defaultMessages[link_privacy]}/>
                    </Button>
                </AppBarSection>
            </AppBar>

        </React.Fragment>
    );
};

export default Footer;